// Colors palette
$orange: #c35835;
$yellow: #fab400;
$blue: #416680;
$light-blue: #c8d8e7;
$green: #4daf4a;
$light-green: #cbe7cd;
$white: #fff;
$black: #1f1f1f;
$dark-grey: #4d4d4d;
$grey: #757575;
$light-grey: #eceff2;
$lighter-grey: #f7f9f9;
$grey-blue: #bac9d1;

// System colors
$red: #cf2140;

// Named colors
$font-color: $black;
$font-contrast-color: $white;
$bg-color: $lighter-grey;

// Theme colors
$companies-color: #9fc1ed;
$employees-color: #edb34e;
$interns-color: #a7b85c;
$work-studies-color: #8ac0c0;
$students-color: #98a0c1;

// Fonts
$font-stack: montserrat, arial, sans-serif;

// Z-indexes
$overlay-z: 100;
$ui-tour-z: 199;
$ui-tour-overlay-z: 200;
$ui-tour-highlight-z: 201;
$ui-tour-tooltip-z: 300;

// Misc
$disable-opacity: 0.5;

// Animation
$quick-timing: 0.2s;
$timing: 0.35s;
$long-timing: 0.5s;
$easing: ease-out;

// Breakpoints
$breakpoints: (
  'x-small': 599px,
  'small': 959px,
  'medium': 1279px,
  'large': 1919px,
  // 'x-large': ...,
) !default;
