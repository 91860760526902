@use 'src/assets/styles/app';

body {
  margin: 0;
  font-family: app.$font-stack;
  color: app.$font-color;
  background-color: app.$bg-color;

  @include app.body-1;

  &.no-scroll {
    overflow: hidden;
  }
}

pre,
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  @include app.h1;
}

h2 {
  @include app.h2;
}

h3 {
  @include app.h3;
}

h4 {
  @include app.h4;
}

h5 {
  @include app.h5;
}

h6 {
  @include app.h6;
}
