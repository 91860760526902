@use 'src/assets/styles/app';

:root {
  // heading-1
  --h1-font-size: 32px;
  --h1-line-height: normal;
  --h1-font-weight: 600;
  --h1-letter-spacing: normal;
  --h1-font-style: normal;
  --h1-text-transform: none;

  // heading-2
  --h2-font-size: 26px;
  --h2-line-height: normal;
  --h2-font-weight: 900;
  --h2-letter-spacing: normal;
  --h2-font-style: normal;
  --h2-text-transform: none;

  // heading-3
  --h3-font-size: 24px;
  --h3-line-height: normal;
  --h3-font-weight: 700;
  --h3-letter-spacing: normal;
  --h3-font-style: normal;
  --h3-text-transform: none;

  // heading-4
  --h4-font-size: 20px;
  --h4-line-height: normal;
  --h4-font-weight: 700;
  --h4-letter-spacing: 0.15px;
  --h4-font-style: normal;
  --h4-text-transform: none;

  // heading-5
  --h5-font-size: 18px;
  --h5-line-height: normal;
  --h5-font-weight: 700;
  --h5-letter-spacing: 0.15px;
  --h5-font-style: normal;
  --h5-text-transform: none;

  // heading-6
  --h6-font-size: 16px;
  --h6-line-height: normal;
  --h6-font-weight: 600;
  --h6-letter-spacing: 0.15px;
  --h6-font-style: normal;
  --h6-text-transform: none;

  // body-1
  --body1-font-size: 18px;
  --body1-line-height: normal;
  --body1-font-weight: 400;
  --body1-letter-spacing: 0.5px;
  --body1-font-style: normal;
  --body1-text-transform: none;

  // body-2
  --body2-font-size: 16px;
  --body2-line-height: normal;
  --body2-font-weight: 500;
  --body2-letter-spacing: normal;
  --body2-font-style: normal;
  --body2-text-transform: none;

  // subtitle-1
  --sub1-font-size: 16px;
  --sub1-line-height: normal;
  --sub1-font-weight: 500;
  --sub1-letter-spacing: 0.15px;
  --sub1-font-style: normal;
  --sub1-text-transform: none;

  // subtitle-2
  --sub2-font-size: 14px;
  --sub2-line-height: normal;
  --sub2-font-weight: 500;
  --sub2-letter-spacing: normal;
  --sub2-font-style: normal;
  --sub2-text-transform: none;

  // button
  --button-font-size: 14px;
  --button-line-height: normal;
  --button-font-weight: 700;
  --button-letter-spacing: normal;
  --button-font-style: normal;
  --button-text-transform: uppercase;

  // caption
  --caption-font-size: 14px;
  --caption-line-height: normal;
  --caption-font-weight: 500;
  --caption-letter-spacing: normal;
  --caption-font-style: normal;
  --caption-text-transform: none;

  // overline
  --overline-font-size: 12px;
  --overline-line-height: 16px;
  --overline-font-weight: 500;
  --overline-letter-spacing: normal;
  --overline-font-style: normal;
  --overline-text-transform: none;

  @include app.mobile {
    // heading-1
    --h1-font-size: 26px;

    // heading-2
    --h2-font-size: 24px;

    // subtitle-1
    --sub1-font-size: 14px;
  }
}
