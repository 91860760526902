@use 'sass:map';
@use '../variables';

// Responsive

@mixin device($breakpoint) {
  @media (max-width: map.get($breakpoints, $breakpoint)) {
    @content;
  }
}

@mixin mobile {
  @include device(x-small) {
    @content;
  }
}

@mixin tablet {
  @include device(small) {
    @content;
  }
}
