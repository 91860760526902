// Load specific fonts

@font-face {
  font-family: montserrat;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    local(''),
    url('../../fonts/montserrat-regular.woff2') format('woff2');
}

@font-face {
  font-family: montserrat;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src:
    local(''),
    url('../../fonts/montserrat-italic.woff2') format('woff2');
}

@font-face {
  font-family: montserrat;
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src:
    local(''),
    url('../../fonts/montserrat-medium-italic.woff2') format('woff2');
}

@font-face {
  font-family: montserrat;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src:
    local(''),
    url('../../fonts/montserrat-medium.woff2') format('woff2');
}

@font-face {
  font-family: montserrat;
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src:
    local(''),
    url('../../fonts/montserrat-semibold-italic.woff2') format('woff2');
}

@font-face {
  font-family: montserrat;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src:
    local(''),
    url('../../fonts/montserrat-semibold.woff2') format('woff2');
}

@font-face {
  font-family: montserrat;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
    local(''),
    url('../../fonts/montserrat-bold.woff2') format('woff2');
}

@font-face {
  font-family: montserrat;
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src:
    local(''),
    url('../../fonts/montserrat-bold-italic.woff2') format('woff2');
}

@font-face {
  font-family: montserrat;
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src:
    local(''),
    url('../../fonts/montserrat-extrabold.woff2') format('woff2');
}

@font-face {
  font-family: montserrat;
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src:
    local(''),
    url('../../fonts/montserrat-extrabold-italic.woff2') format('woff2');
}

@font-face {
  font-family: montserrat;
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src:
    local(''),
    url('../../fonts/montserrat-black.woff2') format('woff2');
}

@font-face {
  font-family: montserrat;
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src:
    local(''),
    url('../../fonts/montserrat-black-italic.woff2') format('woff2');
}
