@use 'src/assets/styles/app';

// Layout

@keyframes resize-loader {
  0% {
    transform: scaleX(0.3);
  }

  50% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0.3);
  }
}

@keyframes move-loader {
  0% {
    left: -80%;
  }

  100% {
    left: 110%;
  }
}

// Effects

@keyframes ripple {
  to {
    opacity: 0;
    transform: scale(2);
  }
}

@keyframes open-select {
  0% {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
  }

  99% {
    overflow: hidden;
  }

  100% {
    opacity: 1;
    max-height: 380px;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes slide-left {
  0% {
    opacity: 0;
    transform: translateX(10px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-right {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes scale-in {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
